var newsContainerEl = document.querySelector('.news-list');
var mixer;

if (newsContainerEl) {
    var mixer = mixitup(newsContainerEl, {
        selectors: {
            target: '.card'
        },
        animation: {
            duration: 250,
            nudge: true,
            reverseOut: false,
            effects: "fade translateZ(-100px)"
        },
        pagination: {
            limit: 9
        }
    });
}
