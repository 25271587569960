var containerEl = document.querySelector('.team-members');
var mixer;

if (containerEl) {
    var mixer = mixitup(containerEl, {
        selectors: {
            target: '.team-member'
        },
        animation: {
            duration: 250,
            nudge: true,
            reverseOut: false,
            effects: "fade translateZ(-100px)"
        }
    });
    mixer.filter('.geese');
}
