const image_slider = new Swiper('.image-slider', {
    slidesPerView: 1,
    centeredSlides: true,
    autoHeight: true,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-image-next',
        prevEl: '.swiper-image-prev',
    },
});
