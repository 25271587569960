var mixer;
var container = document.querySelector('.work-grid');
var alert = document.getElementById("filter-alert");
var resetBtn = document.querySelector('.filter-controls__reset');
var hash = window.location.hash;

if (container) {
    if (hash.replace('#', '') == '') {
        hash = 'all';
    } else {
        hash = '.' + hash.replace('#', '');
    }

    var select_audience = document.querySelector('#filter-audience');
    var select_theme = document.querySelector('#filter-theme');
    var select_type = document.querySelector('#filter-type');

    for (i = 0; i < select_audience.length; ++i){
        if (select_audience.options[i].value == hash){
            select_audience.value = hash;
        }
    }

    for (i = 0; i < select_theme.length; ++i){
        if (select_theme.options[i].value == hash){
            select_theme.value = hash;
        }
    }

    for (i = 0; i < select_type.length; ++i){
        if (select_type.options[i].value == hash){
            select_type.value = hash;
        }
    }

    var mixer = mixitup(container, {
        selectors: {
            target: '.card--post'
        },
        animation: {
            duration: 200,
            nudge: true,
            reverseOut: false,
        },
        load: {
            filter: hash
        },
        multifilter: {
            enable: true 
        },
        pagination: {
            limit: 9
        },
        callbacks: {
            onMixFail: function(state) {
                alert.style.opacity = "1";
            },
            onMixStart: function(state) {
                alert.style.opacity = "0"; 
            },
            onPaginateEnd: function(state) {
                var anchor = document.querySelector('#filter');
                anchor.scrollIntoView();
            }
        }
    });
}

// Reset filters when the reset button is clicked
if (resetBtn) {
    resetBtn.addEventListener('click', () => {
        resetAudienceFilter();
        resetThemeFilter();
        resetTypeFilter();
    }, false);
}

document.addEventListener('input', function (event) {

	// Only run on our select menu
	if (event.target.id !== 'filter-audience') return;

    // Reset filters
    resetThemeFilter();
    resetTypeFilter();

    // Need to use the timeout function to wait for Mix It Up to run
    setTimeout(function () {
        var state = mixer.getState(); 
        updateThemeFilter(state);
        updateTypeFilter(state);
        // console.log('running audience filter');
    }, 350);

}, false);

document.addEventListener('input', function (event) {

	// Only run on our select menu
	if (event.target.id !== 'filter-theme') return;

    // Reset filters
    resetAudienceFilter();
    resetTypeFilter();

    // Need to use the timeout function to wait for Mix It Up to run
    setTimeout(function () {
        var state = mixer.getState(); 
        updateAudienceFilter(state);
        updateTypeFilter(state);
        // console.log('running theme filter');
    }, 350);

}, false);

document.addEventListener('input', function (event) {

	// Only run on our select menu
	if (event.target.id !== 'filter-type') return;

    // Reset filters
    resetAudienceFilter();
    resetThemeFilter();

    // Need to use the timeout function to wait for Mix It Up to run
    setTimeout(function () {
        var state = mixer.getState(); 
        updateAudienceFilter(state);
        updateThemeFilter(state);
        // console.log('running type filter');
    }, 350);

}, false);

function updateAudienceFilter(state) {
    var classes = '';

    state.matching.forEach(function (value) {
        classes += value.classList;
    });

    document.querySelectorAll("#filter-audience option").forEach(opt => {
        var val = opt.value.replace(".", "");
        var match = classes.includes(val);

        if (!match) {
            opt.disabled = true;
        }
    });
}

function updateThemeFilter(state) {
    var classes = '';

    state.matching.forEach(function (value) {
        classes += value.classList;
    });

    document.querySelectorAll("#filter-theme option").forEach(opt => {
        var val = opt.value.replace(".", "");
        var match = classes.includes(val);

        if (!match) {
            opt.disabled = true;
        }
    });
}

function updateTypeFilter(state) {
    var classes = '';

    state.matching.forEach(function (value) {
        classes += value.classList;
    });

    document.querySelectorAll("#filter-type option").forEach(opt => {
        var val = opt.value.replace(".", "");
        var match = classes.includes(val);

        if (!match) {
            opt.disabled = true;
        }
    });
}

function resetAudienceFilter() {
    document.querySelectorAll("#filter-audience option").forEach(opt => {
        opt.disabled = false;
    });
}

function resetThemeFilter() {
    document.querySelectorAll("#filter-theme option").forEach(opt => {
        opt.disabled = false;
    });
}

function resetTypeFilter() {
    document.querySelectorAll("#filter-type option").forEach(opt => {
        opt.disabled = false;
    });
}
