const timeline = new Swiper('.timeline', {
    slidesPerView: 1,
    navigation: {
        nextEl: '#timeline-next',
        prevEl: '#timeline-prev',
    },
    breakpoints: {
        800: {
            slidesPerView: 3,
            centeredSlides: true,
        },
    },
});
